<template>
  <v-container fluid fill-height class="d-flex justify-center align-center">
    <div class="d-flex flex-column justify-center align-center">
      <v-img src="@/assets/loading.gif" width="200px" contain class="mb-5" />
    </div>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("auth", {
      verifyEmail: "verifyEmail",
      fetchProfile: "fetchProfile"
    })
  },
  mounted() {
    this.verifyEmail({
      activation_token: this.$route.query.activation_token,
      _id: this.$route.query.id,
      type: this.$route.query.type
    })
      .then(res => {
        this.fetchProfile();
        this.$router.push({
          name: "home",
          params: { notifications: [res] }
        });
      })
      .catch(error => {
        this.$router.push({
          name: "home",
          params: {
            error: [
              error.response && error.response.data
                ? error.response.data.message
                : error
            ]
          }
        });
      });
  }
};
</script>
<style scoped></style>
